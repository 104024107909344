import { Image } from "@unpic/react";

export function HowItWorksSection(props: {
  steps: Array<{
    name: string;
    description: string;
    img: {
      src: string;
      width: number;
      height: number;
      alt: string;
    };
  }>;
}) {
  return (
    <div className="relative bg-brand-mint_green-800 py-24 sm:py-32 lg:py-40">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-3xl text-center text-white">
          <p>How It Works</p>
          <h2 className="mt-2 text-3xl font-bold tracking-tight sm:text-4xl">
            Receive photos & videos in {props.steps.length} easy steps
          </h2>
        </div>
        <div className="mt-11 flex w-full justify-center md:mt-16">
          <dl className="flex w-full flex-col items-center justify-between gap-12 lg:flex-row lg:items-start">
            {props.steps.map((step, index) => (
              <div key={step.name} className="flex w-full max-w-xs flex-col">
                <div className="mb-6 flex h-full flex-col items-center text-4xl">
                  <Image
                    src={step.img.src}
                    height={step.img.height}
                    width={step.img.width}
                    className="h-full max-h-[420px] rounded-lg"
                  />
                </div>
                <div className="flex flex-col text-white">
                  <dt className="flex items-center gap-x-3 text-lg font-semibold leading-7 ">
                    {index + 1}. {step.name}
                  </dt>
                  <dd className="mt-1 flex flex-auto flex-col text-base leading-7 sm:mt-4">
                    <p className="flex-auto">{step.description}</p>
                  </dd>
                </div>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
