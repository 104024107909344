import { Currency } from "#client/common/ui/Currency.tsx";
import { LinkButton } from "#client/common/ui/LinkButton.tsx";
import { cn } from "#client/common/utils.ts";
import { CheckIcon } from "@heroicons/react/20/solid";

export function PricingSection(props: {
  tiers: {
    free: {
      id: string;
      name: string;
      priceUSD: number;
      description: string;
      features: Array<string>;
      bestValue: boolean;
    };
    plus: {
      id: string;
      name: string;
      priceUSD: number;
      description: string;
      features: Array<string>;
      bestValue: boolean;
    };
    premium: {
      id: string;
      name: string;
      priceUSD: number;
      description: string;
      features: Array<string>;
      bestValue: boolean;
    };
  };
}) {
  const {
    tiers: { free, plus, premium },
  } = props;
  const tiers = [free, premium, plus];

  return (
    <div id="pricing" className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-brand-mint_green-900">
            Pricing
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Premium quality, at an affordable price
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
          Collect photos and videos of your special day with a low one-time
          payment.
        </p>
        <div className="isolate mx-auto mb-10 mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {tiers.map((tier, tierIdx) => (
            <div
              key={tier.id}
              className={cn(
                tier.bestValue ? "lg:z-10 lg:rounded-b-none" : "lg:mt-8",
                tierIdx === 0 ? "lg:rounded-r-none" : "",
                tierIdx === tiers.length - 1 ? "lg:rounded-l-none" : "",
                "flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10"
              )}
            >
              <div>
                <div className="flex items-center justify-between gap-x-4">
                  <h3
                    id={tier.id}
                    className={cn(
                      tier.bestValue
                        ? "text-brand-mint_green-900"
                        : "text-gray-900",
                      "text-lg font-semibold leading-8"
                    )}
                  >
                    {tier.name}
                  </h3>
                  {tier.bestValue ? (
                    <p className="rounded-full bg-brand-mint_green-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-brand-mint_green-900">
                      Best value
                    </p>
                  ) : null}
                </div>
                <p className="mt-4 text-sm leading-6 text-gray-600">
                  {tier.description}
                </p>
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-4xl font-bold tracking-tight text-gray-900">
                    <Currency amount={tier.priceUSD} currency="USD" />
                  </span>
                  <span className="text-sm font-semibold leading-6 text-gray-600">
                    / one-time
                  </span>
                </p>
                <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon
                        className="h-6 w-5 flex-none text-brand-mint_green-600"
                        aria-hidden="true"
                      />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              <LinkButton
                to="/signup"
                aria-describedby={tier.id}
                intent={tier.bestValue ? "primary_green" : "outline_green"}
                size="full"
                className="mt-8"
              >
                Get started
              </LinkButton>
            </div>
          ))}
        </div>

        <div className="flex flex-col items-start gap-x-8 gap-y-6 rounded-3xl bg-white p-8 ring-1 ring-gray-900/10 sm:gap-y-10 sm:p-10 lg:col-span-2 lg:flex-row lg:items-center">
          <div className="lg:min-w-0 lg:flex-1">
            <h3 className="text-lg font-semibold leading-8 tracking-tight text-brand-mint_green-900">
              Gift Card
            </h3>
            <p className="mt-1 text-base leading-7 text-gray-600">
              Purchase for someone special in your life. A gift code will be
              emailed to you after your purchase.
            </p>
          </div>
          <LinkButton to="/signup" intent="outline_green">
            Get started <span aria-hidden="true">&rarr;</span>
          </LinkButton>
        </div>
      </div>
    </div>
  );
}
