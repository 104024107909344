import { LinkButton } from "#client/common/ui/LinkButton";
import { CheckIcon } from "lucide-react";

// import LiteYouTubeEmbed from "react-lite-youtube-embed";
// import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

export function HeroSection(props: {
  heading: string;
  subHeading: string;
  image: () => React.ReactNode;
}) {
  return (
    <div>
      <div className="relative isolate bg-white pt-6 sm:pt-14">
        <div className="pb-24 sm:pb-32 sm:pt-8 lg:pb-40">
          <div className="mx-auto max-w-7xl px-4 md:px-8">
            <div className="flex w-full flex-col gap-16 lg:flex-row lg:items-center lg:gap-20">
              <div className="lg:max-w-2xl">
                <h1 className="font-semi-bold text-left font-dm_serif_text text-3xl text-gray-900 md:text-5xl">
                  {props.heading}
                </h1>
                <div className="mt-7 flex flex-col justify-start gap-4 text-lg leading-6 text-gray-600 md:max-w-xl">
                  <p className="mb-1 text-left">{props.subHeading}</p>
                </div>
                <div className="mt-7 flex justify-start">
                  <div className="flex flex-col gap-7">
                    <div className="flex w-full flex-row items-center justify-center gap-4 lg:max-w-[330px]">
                      <LinkButton
                        to="https://eventshare.io/event/01HRMZWZGMYSBK08AE7YZKE2Q5"
                        size="full"
                        className="w-full px-6 py-3"
                        intent="outline_gray"
                      >
                        Try demo event
                      </LinkButton>
                      <LinkButton
                        to="/signup"
                        size="full"
                        className="w-full bg-brand-apricot-500 px-6 py-3 hover:bg-brand-apricot-400"
                      >
                        <span className="text-gray-900">
                          Sign-up <span aria-hidden="true">→</span>
                        </span>
                      </LinkButton>
                    </div>
                    <ul className="flex flex-col gap-3 text-left text-base text-gray-600">
                      <li className="flex items-start">
                        <div className="mr-2 mt-0.5">
                          <CheckIcon className="h-5 w-5 text-brand-mint_green-900" />
                        </div>
                        No app install needed for guests
                      </li>
                      <li className="flex items-start">
                        <div className="mr-2 mt-0.5">
                          <CheckIcon className="h-5 w-5 text-brand-mint_green-900" />
                        </div>
                        Unlimited number of guests can view and upload to event
                        gallery
                      </li>
                      <li className="flex items-start">
                        <div className="mr-2 mt-0.5">
                          <CheckIcon className="h-5 w-5 text-brand-mint_green-900" />
                        </div>
                        Easily share photos with everyone afterwards
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {props.image()}
              {/* <div className="h-full max-h-[396px] w-full">
                <LiteYouTubeEmbed
                  id="2AhjhHUTst8"
                  title="EventShare overview video"
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
