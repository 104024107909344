import type { HTMLAttributes, PropsWithChildren } from "react";
import { cn } from "#client/common/utils";
import { CheckIcon, type LucideProps } from "lucide-react";

export function FeatureHeader({
  children,
  className,
  ...props
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) {
  return (
    <div className={cn("mx-auto max-w-4xl text-center", className)} {...props}>
      {children}
    </div>
  );
}

export function FeatureHeading({
  children,
  className,
  ...props
}: PropsWithChildren<HTMLAttributes<HTMLHeadElement>>) {
  return (
    <h2
      className={cn(
        "text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl",
        className
      )}
      {...props}
    >
      {children}
    </h2>
  );
}

export function FeatureSubHeading({
  children,
  className,
  ...props
}: PropsWithChildren<HTMLAttributes<HTMLParagraphElement>>) {
  return (
    <div className="flex w-full justify-center">
      <p
        className={cn("mt-4 max-w-2xl text-xl text-gray-500", className)}
        {...props}
      >
        {children}
      </p>
    </div>
  );
}

export function FeatureSection({
  children,
  className,
  direction,
  ...props
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>> & {
  direction: "regular" | "reverse";
}) {
  return (
    <div
      className={cn(
        "flex flex-col items-center gap-8 py-20 lg:flex-row lg:justify-between",
        direction === "regular" ? "lg:flex-row" : "lg:flex-row-reverse",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}

export function FeatureSectionContent({
  children,
  className,
  ...props
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) {
  return (
    <div
      className={cn("mt-6 w-full sm:max-w-2xl lg:mt-0 lg:max-w-md", className)}
      {...props}
    >
      {children}
    </div>
  );
}

export function FeatureContentHeading({
  children,
  className,
  ...props
}: PropsWithChildren<HTMLAttributes<HTMLHeadElement>>) {
  return (
    <h3
      className={cn("text-2xl font-bold text-gray-900 lg:text-3xl", className)}
      {...props}
    >
      {children}
    </h3>
  );
}

export function FeatureContentDescription({
  children,
  className,
  ...props
}: PropsWithChildren<HTMLAttributes<HTMLParagraphElement>>) {
  return (
    <p
      className={cn(
        "mt-4 text-base font-normal text-gray-500 lg:text-lg",
        className
      )}
      {...props}
    >
      {children}
    </p>
  );
}

export function FeatureContentList({
  children,
  className,
  ...props
}: PropsWithChildren<HTMLAttributes<HTMLUListElement>>) {
  return (
    <ul
      className={cn(
        "mt-6 flex flex-col gap-3 text-lg font-normal text-gray-500",
        className
      )}
      {...props}
    >
      {children}
    </ul>
  );
}

export function FeatureContentListItem({
  children,
  className,
  ...props
}: PropsWithChildren<HTMLAttributes<HTMLLIElement>>) {
  return (
    <li className={cn("flex items-start", className)} {...props}>
      {children}
    </li>
  );
}

export function FeatureContentItemBullet(props: LucideProps) {
  return (
    <div className="mr-2 mt-1">
      <CheckIcon
        className={cn("h-5 w-5 text-brand-mint_green-900", props.className)}
        {...props}
      />
    </div>
  );
}
